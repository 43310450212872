import { Dialog } from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import { Toaster, toast } from "sonner";
import { CompanyDomainAutocompleteForLeftbar } from "@/components/workflow-inputs/companyWebsiteAutocomplete";
import { Button } from "@/components/ui/button";
import { FrontendCompany } from "@/services/autogen";
import { findSimilarCompaniesLight } from "@/services/brain-api.service";
import { CompanyListTableLight } from "@/components/company-lists/GiantTable/CompanyListTableLight";
import { useLoaderData } from "react-router-dom";
import posthog from "posthog-js";
import { isAxiosError } from "axios";
import { isValidUrl } from "@/lib/utils";
import { useRef } from "react";

export function FindSimilarPage() {
  const [company, setCompany] = useState("");
  const [results, setResults] = useState<FrontendCompany[]>([]);

  const loaderData = useLoaderData() as { companyDomain: string } | null;

  const isInitialMount = useRef(true);

  const handleSubmit = async (company_domain_override?: string) => {
    const companyDomain = company_domain_override || company;
    posthog.capture("user_used_find_similar_companies_demo", {
      company_domain: companyDomain
    });
    if (!companyDomain || !isValidUrl(companyDomain)) {
      toast.error("Please enter a valid company domain");
      return;
    }

    try {
      setResults([]);
      toast.info("Searching for similar companies.. takes about 20 seconds");

      const userId = posthog.get_distinct_id();

      let timeoutId: NodeJS.Timeout | null = null;

      const toastTimeoutPromise = new Promise<void>((resolve) => {
        timeoutId = setTimeout(() => {
          toast.info("Sorry it's taking a while... we may not have this company in our index");
          resolve();
        }, 20000);
      });

      const fetchPromise = findSimilarCompaniesLight(companyDomain, userId);

      const result = await Promise.race([fetchPromise, toastTimeoutPromise]);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (result === undefined) {
        // This means the toastTimeoutPromise resolved first
        // Now we wait for the fetchPromise to complete
        const fetchResult = await fetchPromise;
        setResults(fetchResult.data);
      } else {
        // The fetchPromise resolved before the timeout
        setResults(result.data);
      }
      toast.success("Search complete!");
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.error(
          <div className="flex flex-col items-center flex-wrap w-full">
            <div>Out of demo searches</div>
            <Button className="mt-4" size="sm" onClick={() => (window.location.href = "https://app.meticulate.ai")}>
              Try the research engine for free
            </Button>
          </div>
        );
      } else {
        toast.error("Failed to fetch similar companies");
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (loaderData?.companyDomain && company !== loaderData.companyDomain) {
        setCompany(loaderData.companyDomain);
        handleSubmit(loaderData.companyDomain);
      }
    }
  }, [loaderData?.companyDomain]);

  return (
    <Dialog>
      <div
        className="flex flex-col dark relative min-h-screen"
        style={{ background: "radial-gradient(94% 58.3% at 50.3% 82.6%, #2a2541 0%, rgb(17, 16, 19) 100%)" }}>
        <div className="w-screen h-15 bg-[#111013] p-5 flex flex-row items-center">
          <img
            src="/MeticulateIconSquare.svg"
            className="h-10 cursor-pointer"
            onClick={() => (window.location.href = "https://meticulate.ai")}
          />
        </div>
        <div className="p-4 flex flex-col h-full w-screen items-center md:justify-center">
          {/* <div className="flex-grow"></div> */}
          <div className="md:text-4xl text-3xl flex text-[#7F5EFF] font-semibold text-center md:mt-0 mt-5">
            Meticulate - lookalike search
          </div>
          <div className="md:text-3xl text-2xl flex mt-5 mb-10 font-landing font-light text-[#e8e3fd] text-center opacity-80">
            Find similar companies to a target
          </div>
          <div
            className="flex flex-row items-center gap-4 bg-white rounded-lg p-2 mb-5 relative z-50"
            style={{ transformStyle: "preserve-3d" }}>
            <CompanyDomainAutocompleteForLeftbar
              placeholder="Company domain lookup"
              onChange={(e) => setCompany(e)}
              onSubmit={({ value }) => {
                setCompany(value);
              }}
            />
            <Button type="submit" onClick={() => handleSubmit()} className=" w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white">
              Search
            </Button>
          </div>

          <div className="max-w-[min(90vw,1000px)] flex flex-col items-center relative">
            <CompanyListTableLight businesses={results} />
            {results != null && results.length > 0 && (
              <div className="absolute bottom-0 left-0 right-0 h-1/4 bg-gradient-to-b from-transparent to-white flex items-center justify-center z-50 rounded-md">
                <Button
                  className="bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white self-end mb-2"
                  onClick={() => (window.location.href = "https://app.meticulate.ai")}>
                  See More
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toaster position="top-center" richColors />
    </Dialog>
  );
}
