/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { BusinessRow, DataTable } from "./data-table";
import { Column, ColumnDef } from "@tanstack/react-table";
import { Button } from "../../ui/button";
import { CompanyLogo } from "@/components/ui/avatar";
import { employeeRangeText, ensureAbsoluteUrl, formatDollarValueMillions } from "@/lib/utils";
import { FrontendCompany, FrontendCompanyType } from "@/services/autogen";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { ExternalLink, Flame, MoveDown, MoveUp, Network, Plus, Store } from "lucide-react";
import { Acorn, Building, City, Handshake, Notepad, TrendUp, TrendDown } from "@phosphor-icons/react";

import React, { useRef } from "react";

const SortingDirectionIndicator = ({ column }: { column: Column<BusinessRow> }) => {
  if (column.getIsSorted() === "asc") {
    return <MoveDown size={14} />;
  } else if (column.getIsSorted() === "desc") {
    return <MoveUp size={14} />;
  } else {
    return null;
  }
};

export const CompanyTypeChip = ({ type }: { type: FrontendCompanyType }) => {
  switch (type) {
    case "SmallPrivate":
      return (
        <div
          className={`bg-colors-yellow-100 border border-colors-yellow-300 rounded-full px-2 py-1 text-colors-yellow-800 flex flex-row items-center gap-1`}>
          <Store size={14} />
          Small private
        </div>
      );
    case "EarlyStageStartup":
      return (
        <div
          className={`bg-colors-brand-100 border border-colors-brand-300 rounded-full px-2 py-1 text-colors-brand-800 flex flex-row items-center gap-1`}>
          <Acorn size={14} /> Early startup
        </div>
      );
    case "GrowthStageStartup":
      return (
        <div
          className={`bg-colors-success-100 border border-colors-success-300 rounded-full px-2 py-1 text-colors-success-800 flex flex-row items-center gap-1`}>
          <TrendUp size={14} />
          Growth startup
        </div>
      );
    case "EstablishedPrivate":
      return (
        <div
          className={`bg-colors-blue-100 border border-colors-blue-300 rounded-full px-2 py-1 text-colors-blue-800 flex flex-row items-center gap-1`}>
          <Building size={14} />
          Established
        </div>
      );
    case "Public":
      return (
        <div
          className={`bg-colors-gray-modern-100 border border-colors-gray-modern-300 rounded-full px-2 py-1 text-colors-gray-modern-800 flex flex-row items-center gap-1`}>
          <City size={14} />
          Public
        </div>
      );
    case "NotForProfit":
      return (
        <div
          className={`bg-colors-pink-100 border border-colors-pink-300 rounded-full px-2 py-1 text-colors-pink-800 flex flex-row items-center gap-1`}>
          <Handshake size={14} />
          Not for profit
        </div>
      );
    case "Defunct":
      return (
        <div
          className={`bg-colors-error-100 border border-colors-error-300 rounded-full px-2 py-1 text-colors-error-800 flex flex-row items-center gap-1`}>
          <TrendDown size={14} />
          Defunct
        </div>
      );
    case "Subsidiary":
      return (
        <div
          className={`bg-colors-orange-100 border border-colors-orange-300 rounded-full px-2 py-1 text-colors-orange-800 flex flex-row items-center gap-1`}>
          <Network size={14} />
          Subsidiary
        </div>
      );
    // TODO: defunct and subsidiary
    default:
      return null;
  }
};

// TODO: change these to be on the 1-5 scale when hype is done
export const HypeFlameSizeIcon = ({ hype }: { hype: number }) => {
  if (hype <= 3) {
    return (
      <div className="flex flex-row items-end text-colors-error-300">
        {/* <span className="max-h-10 overflow-hidden">{hype}</span> */}
        <Flame size={12} className="" />
      </div>
    );
  } else if (hype <= 5) {
    return (
      <div className="flex flex-row items-end text-colors-error-400">
        {/* <span className="max-h-10 overflow-hidden">{hype}</span> */}
        <Flame size={15} className="" />
      </div>
    );
  } else if (hype <= 7) {
    return (
      <div className="flex flex-row items-end text-colors-error-500">
        {/* <span className="max-h-10 overflow-hidden">{hype}</span> */}
        <Flame size={18} className="" />
      </div>
    );
  } else {
    return (
      <div className="flex flex-row items-end text-colors-error-600">
        {/* <span className="max-h-10 overflow-hidden">{hype}</span> */}
        <Flame size={20} className="" />
      </div>
    );
  }
};

export const CompanyListTableLight = React.memo(({ businesses }: { businesses: FrontendCompany[] }) => {
  const tableWindowRef = useRef<HTMLDivElement>(null);
  // TODO: type this properly
  const tableRows: BusinessRow[] = [];
  businesses.forEach((business, index) => {
    tableRows.push({ ...business, tableRank: index, checked: false });
  });

  const basicColumns: ColumnDef<BusinessRow>[] = [
    {
      accessorKey: "tableRank",
      // header: () => "#",
      header: (header) => (
        <div className="flex flex-row hover:cursor-pointer px-2 w-12" onClick={header.column.getToggleSortingHandler()}>
          #
          <SortingDirectionIndicator column={header.column} />
        </div>
      ),
      size: 15,
      cell: ({ row }) => {
        return (
          <div className="overflow-hidden ellipsis max-h-[64px] px-2 flex flex-row items-center">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.tableRank + 1}</span>
          </div>
        );
      },
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "name",
      // size: 100,
      header: ({}) => {
        return (
          // <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          <h1 className="text-colors-text-text-tertiary-(600) pl-2">Company</h1>
          // <CaretUpDown className="ml-2 h-4 w-4" />
          // </Button>
        );
      },
      cell: ({ row }) => (
        <div className="flex flex-col items-start py-2 pl-2 z-40">
          {row.original.name != null && (
            <div className="flex flex-row items-center">
              <CompanyLogo
                name={row.original.name}
                website={row.original.domain}
                logo={row.original.logo_url}
                className="w-8 h-8 mr-2 opacity-100"
              />
              <div className="flex flex-col">
                <h1 className="font-medium text-colors-text-text-primary-(900) w-full overflow-y-hidden text-sm mb-1">
                  {row.original.name}
                </h1>
                <div className="flex flex-row items-center gap-2">
                  <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden font-bold">
                    {row.original.stock_ticker ?? ""}
                  </span>
                  <a
                    href={ensureAbsoluteUrl(row.original.domain ?? "")}
                    className="flex flex-row hover:cursor-pointer font-medium underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ExternalLink size={14} color="grey" />
                  </a>
                  {row.original.linkedin_aid && (
                    <a
                      href={ensureAbsoluteUrl("linkedin.com/company/" + row.original.linkedin_aid)}
                      className="self-center"
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src="/linkedin.png" className="h-5 w-5 opacity-80" />
                    </a>
                  )}
                  <HypeFlameSizeIcon hype={row.original.hype_rating} />
                </div>
              </div>
            </div>
          )}
        </div>
      ),
      meta: {
        group: "meta"
      }
    },
    {
      accessorKey: "companyType",
      header: () => (
        <div className="flex flex-row items-center">
          <h1 className="text-colors-text-text-tertiary-(600) pl-2">Type</h1>
        </div>
      ),
      size: 160,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5 flex pl-2">
          {/* <CompanyTypeIcon type={row.original.company_type} /> */}
          <CompanyTypeChip type={row.original.company_type} />
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "employee_count",
      // size: 25,
      size: 80,
      header: () => (
        <div>
          <span>Employees</span>
        </div>
      ),
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-center">
          {/* TODO: show the range */}
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {employeeRangeText(row.original.employee_min, row.original.employee_max, row.original.employee_guess)}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Employee Count"
      }
      // HACK: strings should be allowed but type checker doesn't like it... no time to delve deeper
      // sortingFn: "reportedMetricSorting" as SortingFnOption<BusinessRow>
    },
    {
      accessorKey: "overview",
      header: () => "Overview",
      size: 180,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.overview.text}</span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Overview"
      }
    },
    {
      accessorKey: "business_model",
      header: () => "Business model",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.business_model.text}</span>
          <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Business Model"
      }
    },
    {
      accessorKey: "main_offerings",
      header: () => "Main offerings",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.main_offerings.text}</span>
          <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Main Offerings"
      }
    },
    {
      accessorKey: "customer_groups",
      header: () => "Customer groups",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {row.original.summary.customer_segments.text}
          </span>
          <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Customer groups"
      }
    },
    {
      accessorKey: "geographies",
      header: () => "Geographies",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.geographies.text}</span>
          <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Geographies"
      }
    },
    {
      accessorKey: "technologies",
      header: () => "Technologies",
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.summary.technologies.text}</span>
          <div className="absolute bottom-0 left-0 right-0 h-[50%] bg-gradient-to-t from-white via-[rgba(255,255,255,0.9)] to-transparent"></div>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Technologies"
      }
    },
    {
      accessorKey: "headquarters",
      size: 100,
      header: () => <div className="w-auto">HQ</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.hq_str}</span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Headquarters"
      }
    },
    // DROPPING THIS FROM TABLE BECAUSE DATA IS SUPER SPARSE
    // {
    //   accessorKey: "investors",
    //   header: () => <div className="w-auto">Investors</div>,
    //   cell: ({ row }) => (
    //     <div className="overflow-hidden ellipsis w-auto max-h-[64px] pr-5">
    //       <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.list_of_investors}</span>
    //     </div>
    //   ),
    //   meta: {
    //     group: "basic_info",
    //     displayName: "Investors"
    //   }
    // },
    {
      accessorKey: "funds_raised",
      size: 100,
      header: () => <div className="w-auto justify-end text-right">Known fundraising</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
            {/* TODO: format this properly */}
            {row.original.total_known_fundraising?.amount ? formatDollarValueMillions(row.original.total_known_fundraising.amount) : "-"}
          </span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Known Fundraising"
      }
    },
    {
      accessorKey: "founded",
      size: 80,
      header: () => <div className="justify-end text-right">Founded</div>,
      cell: ({ row }) => (
        <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
          <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">{row.original.year_founded}</span>
        </div>
      ),
      meta: {
        group: "basic_info",
        displayName: "Founding year"
      }
    },
    {
      accessorKey: "web_traffic",
      size: 80,
      header: () => <div className="justify-end text-right">Web traffic</div>,
      cell: ({ row }) => {
        const visitsLastMonth = row.original.web_traffic?.visits_last_month;
        const visitGrowthMonthly = row.original.web_traffic?.visit_growth_monthly;
        const formattedVisits = visitsLastMonth
          ? visitsLastMonth >= 1000000
            ? (visitsLastMonth / 1000000).toFixed(1) + "M"
            : (visitsLastMonth / 1000).toFixed(0) + "k"
          : visitsLastMonth;
        const growthColor = visitGrowthMonthly !== undefined && visitGrowthMonthly > 0 ? "green" : "red";
        const formattedGrowth =
          visitGrowthMonthly !== undefined ? `${visitGrowthMonthly > 0 ? "+" : "-"}${Math.abs(visitGrowthMonthly * 100).toFixed(1)}%` : "";

        return (
          <div className="overflow-hidden ellipsis w-auto max-h-[64px] justify-end text-right">
            <span className="text-colors-text-text-secondary-(700) max-h-10 overflow-hidden">
              {formattedVisits}
              <br />
              <span style={{ color: visitGrowthMonthly !== undefined ? growthColor : "inherit" }}>{formattedGrowth}</span>
            </span>
          </div>
        );
      },
      meta: {
        group: "basic_info",
        displayName: "Web traffic"
      }
    }
  ];
  const newColumns = [...basicColumns];

  const getAllColumns = () => {
    newColumns.push({
      accessorKey: "trash",
      size: 50,
      header: ({ table }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default" size="sm" className="flex justify-center items-center m-auto">
                <Plus size={20} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="overflow-hidden overflow-y-auto">
              <span className="flex items-center justify-start my-1 p-1">
                <Notepad className="mr-1" size={22} />
                <h1 className="font-semi">Basic info</h1>
              </span>
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .filter((column: any) => column?.columnDef?.meta?.group === "basic_info")
                .map((column: any) => {
                  // TODO: stop using any
                  return (
                    <div className="flex items-center justify-start my-1 p-1" key={column.id}>
                      <Checkbox
                        key={column.id}
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) => {
                          column.toggleVisibility(!!value);

                          // sleep for 1 sec
                          setTimeout(() => {
                            // scroll to the right
                            tableWindowRef.current?.scrollTo({ left: tableWindowRef.current.scrollWidth, behavior: "smooth" });
                          }, 100);
                        }}>
                        {column.id}
                      </Checkbox>

                      <p className="capitalize ml-2 text-sm">{column.columnDef.meta?.displayName}</p>
                    </div>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      enableHiding: false,
      cell: ({}) => {
        return <div className="min-w-[22px] min-h-[39px]"></div>;
      }
    });
  };

  getAllColumns();

  return <DataTable columns={newColumns} data={tableRows} ref={tableWindowRef} isRunning={false} />;
  // return <SimpleDataTable columns={newColumns} data={tableRows} />;
});
