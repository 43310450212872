/* tslint:disable */
/* eslint-disable */
/**
 * Meticulate Web Server - OpenAPI 3.0
 * Something something description
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest
 */
export interface BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest
     */
    'company_list_id': string;
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest
     */
    'market_map_image_base64_encoded': string;
}
/**
 * 
 * @export
 * @interface BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest
 */
export interface BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest
     */
    'workflow_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest
     */
    'company_domain'?: string;
}
/**
 * 
 * @export
 * @interface BrainV1DeleteCompanyListPostRequest
 */
export interface BrainV1DeleteCompanyListPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1DeleteCompanyListPostRequest
     */
    'company_list_id': string;
}
/**
 * 
 * @export
 * @interface BrainV1FetchGenericWorkflowPostRequest
 */
export interface BrainV1FetchGenericWorkflowPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1FetchGenericWorkflowPostRequest
     */
    'workflow_id': string;
}
/**
 * 
 * @export
 * @interface BrainV1FindCompaniesInitialInputsToInputsPost200Response
 */
export interface BrainV1FindCompaniesInitialInputsToInputsPost200Response {
    /**
     * 
     * @type {FindCompaniesV2Inputs}
     * @memberof BrainV1FindCompaniesInitialInputsToInputsPost200Response
     */
    'final_inputs': FindCompaniesV2Inputs;
    /**
     * 
     * @type {string}
     * @memberof BrainV1FindCompaniesInitialInputsToInputsPost200Response
     */
    'error_explanation'?: string;
}
/**
 * 
 * @export
 * @interface BrainV1FindSimilarCompaniesLightPostRequest
 */
export interface BrainV1FindSimilarCompaniesLightPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1FindSimilarCompaniesLightPostRequest
     */
    'company_domain': string;
    /**
     * 
     * @type {string}
     * @memberof BrainV1FindSimilarCompaniesLightPostRequest
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface BrainV1GetCompanyProfilePostRequest
 */
export interface BrainV1GetCompanyProfilePostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1GetCompanyProfilePostRequest
     */
    'company_domain': string;
}
/**
 * 
 * @export
 * @interface BrainV1GiveGenericWorkflowFeedbackPostRequest
 */
export interface BrainV1GiveGenericWorkflowFeedbackPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1GiveGenericWorkflowFeedbackPostRequest
     */
    'workflow_id': string;
    /**
     * 
     * @type {WorkflowFeedback}
     * @memberof BrainV1GiveGenericWorkflowFeedbackPostRequest
     */
    'feedback': WorkflowFeedback;
}
/**
 * 
 * @export
 * @interface BrainV1SetUserDefaultScopePostRequest
 */
export interface BrainV1SetUserDefaultScopePostRequest {
    /**
     * 
     * @type {ImportableSearchScope}
     * @memberof BrainV1SetUserDefaultScopePostRequest
     */
    'scope': ImportableSearchScope;
}
/**
 * 
 * @export
 * @interface BrainV1SourceCompaniesInitialInputsToInputsPost200Response
 */
export interface BrainV1SourceCompaniesInitialInputsToInputsPost200Response {
    /**
     * 
     * @type {SourceCompaniesInputs}
     * @memberof BrainV1SourceCompaniesInitialInputsToInputsPost200Response
     */
    'final_inputs': SourceCompaniesInputs;
    /**
     * 
     * @type {string}
     * @memberof BrainV1SourceCompaniesInitialInputsToInputsPost200Response
     */
    'error_explanation'?: string;
}
/**
 * 
 * @export
 * @interface BusinessCommentary
 */
export interface BusinessCommentary {
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessCommentary
     */
    'advantages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessCommentary
     */
    'disadvantages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessCommentary
     */
    'differentiation'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BusinessManagementItem
 */
export interface BusinessManagementItem {
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'linkedin_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface CompLandscapeCompany
 */
export interface CompLandscapeCompany {
    /**
     * 
     * @type {FrontendCompany}
     * @memberof CompLandscapeCompany
     */
    'company': FrontendCompany;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompLandscapeCompany
     */
    'categories_belonging': Array<string>;
}
/**
 * 
 * @export
 * @interface CompLandscapeInputs
 */
export interface CompLandscapeInputs {
    /**
     * 
     * @type {string}
     * @memberof CompLandscapeInputs
     */
    'company_website': string;
    /**
     * 
     * @type {string}
     * @memberof CompLandscapeInputs
     */
    'grouping_instructions'?: string;
    /**
     * 
     * @type {MarketMap}
     * @memberof CompLandscapeInputs
     */
    'market_map'?: MarketMap;
}
/**
 * 
 * @export
 * @interface CompLandscapeOutputs
 */
export interface CompLandscapeOutputs {
    /**
     * 
     * @type {MarketMap}
     * @memberof CompLandscapeOutputs
     */
    'market_map': MarketMap;
    /**
     * 
     * @type {Array<CompLandscapeCompany>}
     * @memberof CompLandscapeOutputs
     */
    'companies': Array<CompLandscapeCompany>;
}
/**
 * 
 * @export
 * @interface CompLandscapeV7Workflow
 */
export interface CompLandscapeV7Workflow {
    /**
     * 
     * @type {CompLandscapeInputs}
     * @memberof CompLandscapeV7Workflow
     */
    'inputs': CompLandscapeInputs;
    /**
     * 
     * @type {CompLandscapeOutputs}
     * @memberof CompLandscapeV7Workflow
     */
    'outputs'?: CompLandscapeOutputs;
    /**
     * 
     * @type {string}
     * @memberof CompLandscapeV7Workflow
     */
    'parse_helper': CompLandscapeV7WorkflowParseHelperEnum;
}

export const CompLandscapeV7WorkflowParseHelperEnum = {
    CompLandscapeV7: 'comp_landscape_v7'
} as const;

export type CompLandscapeV7WorkflowParseHelperEnum = typeof CompLandscapeV7WorkflowParseHelperEnum[keyof typeof CompLandscapeV7WorkflowParseHelperEnum];

/**
 * 
 * @export
 * @interface CompanyList
 */
export interface CompanyList {
    /**
     * 
     * @type {string}
     * @memberof CompanyList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyList
     */
    'title': string;
    /**
     * 
     * @type {Array<FrontendCompany>}
     * @memberof CompanyList
     */
    'companies': Array<FrontendCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyList
     */
    'input_images_base64_encoded'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CompanySummary
 */
export interface CompanySummary {
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'overview': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'business_model': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'main_offerings': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'customer_segments': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'geographies': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'technologies': TextWithSources;
}
/**
 * 
 * @export
 * @interface FindCompaniesOutputsV2
 */
export interface FindCompaniesOutputsV2 {
    /**
     * 
     * @type {Array<FrontendCompany>}
     * @memberof FindCompaniesOutputsV2
     */
    'companies': Array<FrontendCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesOutputsV2
     */
    'removed_company_domains'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FindCompaniesV2InitialInputs
 */
export interface FindCompaniesV2InitialInputs {
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2InitialInputs
     */
    'category_name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesV2InitialInputs
     */
    'exemplar_company_domains'?: Array<string>;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof FindCompaniesV2InitialInputs
     */
    'filters'?: Array<HardFilter>;
    /**
     * 
     * @type {Array<FrontendCompanyType>}
     * @memberof FindCompaniesV2InitialInputs
     */
    'company_type_filter'?: Array<FrontendCompanyType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesV2InitialInputs
     */
    'hq_country_filter'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FindCompaniesV2InitialInputs
     */
    'requested_number_of_companies'?: number;
}
/**
 * 
 * @export
 * @interface FindCompaniesV2Inputs
 */
export interface FindCompaniesV2Inputs {
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'business_model'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'main_offerings'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'customer_segments'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'geographies'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'technologies'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesV2Inputs
     */
    'exemplar_company_domains'?: Array<string>;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof FindCompaniesV2Inputs
     */
    'filters'?: Array<HardFilter>;
    /**
     * 
     * @type {Array<FrontendCompanyType>}
     * @memberof FindCompaniesV2Inputs
     */
    'company_type_filter'?: Array<FrontendCompanyType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesV2Inputs
     */
    'hq_country_filter'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FindCompaniesV2Inputs
     */
    'requested_number_of_companies'?: number;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'market_map_image_base64_encoded'?: string;
}
/**
 * 
 * @export
 * @interface FindCompaniesWorkflowV2
 */
export interface FindCompaniesWorkflowV2 {
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesWorkflowV2
     */
    'input_generation_state'?: FindCompaniesWorkflowV2InputGenerationStateEnum;
    /**
     * 
     * @type {FindCompaniesV2InitialInputs}
     * @memberof FindCompaniesWorkflowV2
     */
    'frontend_initial_inputs'?: FindCompaniesV2InitialInputs;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesWorkflowV2
     */
    'error_explanation'?: string;
    /**
     * 
     * @type {FindCompaniesV2Inputs}
     * @memberof FindCompaniesWorkflowV2
     */
    'inputs': FindCompaniesV2Inputs;
    /**
     * 
     * @type {FindCompaniesOutputsV2}
     * @memberof FindCompaniesWorkflowV2
     */
    'outputs'?: FindCompaniesOutputsV2;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesWorkflowV2
     */
    'parse_helper': FindCompaniesWorkflowV2ParseHelperEnum;
}

export const FindCompaniesWorkflowV2InputGenerationStateEnum = {
    Initial: 'initial',
    Loading: 'loading',
    Final: 'final',
    UploadList: 'upload_list',
    FindSimilar: 'find_similar'
} as const;

export type FindCompaniesWorkflowV2InputGenerationStateEnum = typeof FindCompaniesWorkflowV2InputGenerationStateEnum[keyof typeof FindCompaniesWorkflowV2InputGenerationStateEnum];
export const FindCompaniesWorkflowV2ParseHelperEnum = {
    FindCompaniesV2: 'find_companies_v2'
} as const;

export type FindCompaniesWorkflowV2ParseHelperEnum = typeof FindCompaniesWorkflowV2ParseHelperEnum[keyof typeof FindCompaniesWorkflowV2ParseHelperEnum];

/**
 * 
 * @export
 * @interface FrontendCompany
 */
export interface FrontendCompany {
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'domain': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontendCompany
     */
    'alternate_domains': Array<string>;
    /**
     * 
     * @type {CompanySummary}
     * @memberof FrontendCompany
     */
    'summary': CompanySummary;
    /**
     * 
     * @type {FrontendCompanyType}
     * @memberof FrontendCompany
     */
    'company_type': FrontendCompanyType;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'hype_rating': number;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'linkedin_aid': string;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'employee_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'employee_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'employee_guess'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'year_founded'?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'stock_exchange'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'stock_ticker'?: string;
    /**
     * 
     * @type {Array<Fundraise>}
     * @memberof FrontendCompany
     */
    'fundraises'?: Array<Fundraise>;
    /**
     * 
     * @type {Array<BusinessManagementItem>}
     * @memberof FrontendCompany
     */
    'management'?: Array<BusinessManagementItem>;
    /**
     * 
     * @type {Array<PressItem>}
     * @memberof FrontendCompany
     */
    'recent_press'?: Array<PressItem>;
    /**
     * 
     * @type {BusinessCommentary}
     * @memberof FrontendCompany
     */
    'commentary'?: BusinessCommentary;
    /**
     * 
     * @type {Array<FrontendCompanyStub>}
     * @memberof FrontendCompany
     */
    'similar_companies'?: Array<FrontendCompanyStub>;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'logo_url'?: string;
    /**
     * 
     * @type {{ [key: string]: TextWithSources; }}
     * @memberof FrontendCompany
     */
    'custom_research'?: { [key: string]: TextWithSources; };
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontendCompany
     */
    'list_of_investors'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'hq_str'?: string;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'last_known_fundraise_timestamp'?: number;
    /**
     * 
     * @type {Fundraise}
     * @memberof FrontendCompany
     */
    'total_known_fundraising'?: Fundraise;
    /**
     * 
     * @type {WebTrafficSnapshot}
     * @memberof FrontendCompany
     */
    'web_traffic'?: WebTrafficSnapshot;
}


/**
 * 
 * @export
 * @interface FrontendCompanyStub
 */
export interface FrontendCompanyStub {
    /**
     * 
     * @type {string}
     * @memberof FrontendCompanyStub
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompanyStub
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompanyStub
     */
    'linkedin_aid': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompanyStub
     */
    'logo_url'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FrontendCompanyType = {
    SmallPrivate: 'SmallPrivate',
    EarlyStageStartup: 'EarlyStageStartup',
    GrowthStageStartup: 'GrowthStageStartup',
    EstablishedPrivate: 'EstablishedPrivate',
    Public: 'Public',
    NotForProfit: 'NotForProfit',
    Subsidiary: 'Subsidiary',
    Defunct: 'Defunct'
} as const;

export type FrontendCompanyType = typeof FrontendCompanyType[keyof typeof FrontendCompanyType];


/**
 * 
 * @export
 * @interface Fundraise
 */
export interface Fundraise {
    /**
     * 
     * @type {number}
     * @memberof Fundraise
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Fundraise
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fundraise
     */
    'round_name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Fundraise
     */
    'investors'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Fundraise
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface GenericWorkflow
 */
export interface GenericWorkflow {
    /**
     * 
     * @type {string}
     * @memberof GenericWorkflow
     */
    'workflow_id': string;
    /**
     * 
     * @type {WorkflowType}
     * @memberof GenericWorkflow
     */
    'workflow_type': WorkflowType;
    /**
     * 
     * @type {string}
     * @memberof GenericWorkflow
     */
    'title': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof GenericWorkflow
     */
    'status': TaskStatus;
    /**
     * 
     * @type {WorkflowFeedback}
     * @memberof GenericWorkflow
     */
    'feedback'?: WorkflowFeedback;
    /**
     * 
     * @type {WorkflowEffort}
     * @memberof GenericWorkflow
     */
    'effort'?: WorkflowEffort;
    /**
     * 
     * @type {number}
     * @memberof GenericWorkflow
     */
    'start_timestamp_seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericWorkflow
     */
    'created_at'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericWorkflow
     */
    'viewed'?: boolean;
    /**
     * 
     * @type {GenericWorkflowWorkflow}
     * @memberof GenericWorkflow
     */
    'workflow': GenericWorkflowWorkflow;
    /**
     * 
     * @type {string}
     * @memberof GenericWorkflow
     */
    'error_message'?: string;
}


/**
 * @type GenericWorkflowWorkflow
 * @export
 */
export type GenericWorkflowWorkflow = CompLandscapeV7Workflow | FindCompaniesWorkflowV2 | MarketMapWorkflow | SourceCompaniesWorkflow;

/**
 * 
 * @export
 * @interface HardFilter
 */
export interface HardFilter {
    /**
     * 
     * @type {HardMetric}
     * @memberof HardFilter
     */
    'hard_metric': HardMetric;
    /**
     * 
     * @type {number}
     * @memberof HardFilter
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof HardFilter
     */
    'max'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HardMetric = {
    Ebitda: 'ebitda',
    MarketCap: 'market_cap',
    Revenue: 'revenue',
    EmployeeCount: 'employee_count',
    RevenueGrowth: 'revenue_growth',
    EnterpriseValue: 'enterprise_value',
    GrossMargin: 'gross_margin',
    Earnings: 'earnings',
    FreeCashFlow: 'free_cash_flow',
    FoundedYear: 'founded_year',
    NoFundraisingWindow: 'no_fundraising_window'
} as const;

export type HardMetric = typeof HardMetric[keyof typeof HardMetric];


/**
 * 
 * @export
 * @interface ImportableSearchScope
 */
export interface ImportableSearchScope {
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof ImportableSearchScope
     */
    'filters'?: Array<HardFilter>;
    /**
     * 
     * @type {Array<FrontendCompanyType>}
     * @memberof ImportableSearchScope
     */
    'company_type_filter'?: Array<FrontendCompanyType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportableSearchScope
     */
    'hq_country_filter'?: Array<string>;
    /**
     * 
     * @type {Array<ScoringCriterion>}
     * @memberof ImportableSearchScope
     */
    'scoring_criteria'?: Array<ScoringCriterion>;
}
/**
 * 
 * @export
 * @interface MarketMap
 */
export interface MarketMap {
    /**
     * 
     * @type {string}
     * @memberof MarketMap
     */
    'title'?: string;
    /**
     * 
     * @type {Array<MarketMapCategory>}
     * @memberof MarketMap
     */
    'categories': Array<MarketMapCategory>;
}
/**
 * 
 * @export
 * @interface MarketMapCategory
 */
export interface MarketMapCategory {
    /**
     * 
     * @type {string}
     * @memberof MarketMapCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MarketMapCategory
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapCategory
     */
    'businesses': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapCategory
     */
    'bespoke_research_topics': Array<string>;
    /**
     * 
     * @type {Array<MarketMapSubcategory>}
     * @memberof MarketMapCategory
     */
    'subcategories': Array<MarketMapSubcategory>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapCategory
     */
    'exemplars': Array<string>;
}
/**
 * 
 * @export
 * @interface MarketMapInputs
 */
export interface MarketMapInputs {
    /**
     * 
     * @type {MarketMap}
     * @memberof MarketMapInputs
     */
    'market_map': MarketMap;
}
/**
 * 
 * @export
 * @interface MarketMapOutputs
 */
export interface MarketMapOutputs {
    /**
     * 
     * @type {MarketMap}
     * @memberof MarketMapOutputs
     */
    'market_map': MarketMap;
    /**
     * 
     * @type {Array<CompLandscapeCompany>}
     * @memberof MarketMapOutputs
     */
    'companies': Array<CompLandscapeCompany>;
}
/**
 * 
 * @export
 * @interface MarketMapSubcategory
 */
export interface MarketMapSubcategory {
    /**
     * 
     * @type {string}
     * @memberof MarketMapSubcategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MarketMapSubcategory
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapSubcategory
     */
    'businesses': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapSubcategory
     */
    'bespoke_research_topics': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapSubcategory
     */
    'exemplars': Array<string>;
}
/**
 * 
 * @export
 * @interface MarketMapWorkflow
 */
export interface MarketMapWorkflow {
    /**
     * 
     * @type {MarketMapInputs}
     * @memberof MarketMapWorkflow
     */
    'inputs': MarketMapInputs;
    /**
     * 
     * @type {MarketMapOutputs}
     * @memberof MarketMapWorkflow
     */
    'outputs'?: MarketMapOutputs;
    /**
     * 
     * @type {string}
     * @memberof MarketMapWorkflow
     */
    'parse_helper': MarketMapWorkflowParseHelperEnum;
}

export const MarketMapWorkflowParseHelperEnum = {
    MarketMapV2: 'market_map_v2'
} as const;

export type MarketMapWorkflowParseHelperEnum = typeof MarketMapWorkflowParseHelperEnum[keyof typeof MarketMapWorkflowParseHelperEnum];

/**
 * 
 * @export
 * @interface MetricWithSources
 */
export interface MetricWithSources {
    /**
     * 
     * @type {number}
     * @memberof MetricWithSources
     */
    'value': number;
    /**
     * 
     * @type {Array<Source>}
     * @memberof MetricWithSources
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface PressItem
 */
export interface PressItem {
    /**
     * 
     * @type {string}
     * @memberof PressItem
     */
    'takeaway': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PressItem
     */
    'quotes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PressItem
     */
    'featured': boolean;
    /**
     * 
     * @type {Source}
     * @memberof PressItem
     */
    'source': Source;
}
/**
 * 
 * @export
 * @interface ScoringAnswer
 */
export interface ScoringAnswer {
    /**
     * 
     * @type {string}
     * @memberof ScoringAnswer
     */
    'question': string;
    /**
     * 
     * @type {TextWithSources}
     * @memberof ScoringAnswer
     */
    'answer'?: TextWithSources;
    /**
     * 
     * @type {number}
     * @memberof ScoringAnswer
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface ScoringCriterion
 */
export interface ScoringCriterion {
    /**
     * 
     * @type {string}
     * @memberof ScoringCriterion
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof ScoringCriterion
     */
    'detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScoringCriterion
     */
    'low_score_standard': string;
    /**
     * 
     * @type {string}
     * @memberof ScoringCriterion
     */
    'high_score_standard': string;
    /**
     * 
     * @type {number}
     * @memberof ScoringCriterion
     */
    'weight': number;
}
/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'gateway'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'snippet'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'source_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface SourceCompaniesCompany
 */
export interface SourceCompaniesCompany {
    /**
     * 
     * @type {FrontendCompany}
     * @memberof SourceCompaniesCompany
     */
    'company': FrontendCompany;
    /**
     * 
     * @type {number}
     * @memberof SourceCompaniesCompany
     */
    'overall_score'?: number;
    /**
     * 
     * @type {Array<ScoringAnswer>}
     * @memberof SourceCompaniesCompany
     */
    'scoring_answers'?: Array<ScoringAnswer>;
}
/**
 * 
 * @export
 * @interface SourceCompaniesInputs
 */
export interface SourceCompaniesInputs {
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'business_model'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'main_offerings'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'customer_segments'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'geographies'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'technologies'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceCompaniesInputs
     */
    'exemplar_company_domains'?: Array<string>;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof SourceCompaniesInputs
     */
    'filters'?: Array<HardFilter>;
    /**
     * 
     * @type {Array<FrontendCompanyType>}
     * @memberof SourceCompaniesInputs
     */
    'company_type_filter'?: Array<FrontendCompanyType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceCompaniesInputs
     */
    'hq_country_filter'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SourceCompaniesInputs
     */
    'requested_number_of_companies'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceCompaniesInputs
     */
    'exemplar_domains'?: Array<string>;
    /**
     * 
     * @type {Array<ScoringCriterion>}
     * @memberof SourceCompaniesInputs
     */
    'scoring_criteria'?: Array<ScoringCriterion>;
}
/**
 * 
 * @export
 * @interface SourceCompaniesOutputs
 */
export interface SourceCompaniesOutputs {
    /**
     * 
     * @type {Array<SourceCompaniesCompany>}
     * @memberof SourceCompaniesOutputs
     */
    'companies': Array<SourceCompaniesCompany>;
    /**
     * 
     * @type {Array<SourceCompaniesCompany>}
     * @memberof SourceCompaniesOutputs
     */
    'removed_companies'?: Array<SourceCompaniesCompany>;
}
/**
 * 
 * @export
 * @interface SourceCompaniesWorkflow
 */
export interface SourceCompaniesWorkflow {
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesWorkflow
     */
    'input_generation_state'?: SourceCompaniesWorkflowInputGenerationStateEnum;
    /**
     * 
     * @type {SourceCompaniesInputs}
     * @memberof SourceCompaniesWorkflow
     */
    'frontend_initial_inputs'?: SourceCompaniesInputs;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesWorkflow
     */
    'error_explanation'?: string;
    /**
     * 
     * @type {SourceCompaniesInputs}
     * @memberof SourceCompaniesWorkflow
     */
    'inputs': SourceCompaniesInputs;
    /**
     * 
     * @type {SourceCompaniesOutputs}
     * @memberof SourceCompaniesWorkflow
     */
    'outputs'?: SourceCompaniesOutputs;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesWorkflow
     */
    'parse_helper': SourceCompaniesWorkflowParseHelperEnum;
}

export const SourceCompaniesWorkflowInputGenerationStateEnum = {
    Initial: 'initial',
    Loading: 'loading',
    Final: 'final'
} as const;

export type SourceCompaniesWorkflowInputGenerationStateEnum = typeof SourceCompaniesWorkflowInputGenerationStateEnum[keyof typeof SourceCompaniesWorkflowInputGenerationStateEnum];
export const SourceCompaniesWorkflowParseHelperEnum = {
    SourceCompanies: 'source_companies'
} as const;

export type SourceCompaniesWorkflowParseHelperEnum = typeof SourceCompaniesWorkflowParseHelperEnum[keyof typeof SourceCompaniesWorkflowParseHelperEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const TaskStatus = {
    Created: 'created',
    Running: 'running',
    Completed: 'completed',
    InputModified: 'input_modified',
    OutputModified: 'output_modified',
    Incomplete: 'incomplete'
} as const;

export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


/**
 * 
 * @export
 * @interface TextWithSource
 */
export interface TextWithSource {
    /**
     * 
     * @type {string}
     * @memberof TextWithSource
     */
    'text': string;
    /**
     * 
     * @type {Source}
     * @memberof TextWithSource
     */
    'source': Source;
}
/**
 * 
 * @export
 * @interface TextWithSources
 */
export interface TextWithSources {
    /**
     * 
     * @type {string}
     * @memberof TextWithSources
     */
    'text': string;
    /**
     * 
     * @type {Array<Source>}
     * @memberof TextWithSources
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface TextWithSourcesAndHeadline
 */
export interface TextWithSourcesAndHeadline {
    /**
     * 
     * @type {string}
     * @memberof TextWithSourcesAndHeadline
     */
    'text': string;
    /**
     * 
     * @type {Array<Source>}
     * @memberof TextWithSourcesAndHeadline
     */
    'sources': Array<Source>;
    /**
     * 
     * @type {string}
     * @memberof TextWithSourcesAndHeadline
     */
    'headline': string;
}
/**
 * 
 * @export
 * @interface TextsWithSource
 */
export interface TextsWithSource {
    /**
     * 
     * @type {Array<string>}
     * @memberof TextsWithSource
     */
    'texts': Array<string>;
    /**
     * 
     * @type {Source}
     * @memberof TextsWithSource
     */
    'source': Source;
}
/**
 * 
 * @export
 * @interface WebTrafficSnapshot
 */
export interface WebTrafficSnapshot {
    /**
     * 
     * @type {number}
     * @memberof WebTrafficSnapshot
     */
    'visits_last_month': number;
    /**
     * 
     * @type {number}
     * @memberof WebTrafficSnapshot
     */
    'visit_growth_monthly'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WebTrafficSnapshot
     */
    'visits_by_month'?: Array<number>;
}
/**
 * 
 * @export
 * @interface WorkflowEffort
 */
export interface WorkflowEffort {
    /**
     * 
     * @type {number}
     * @memberof WorkflowEffort
     */
    'number_of_articles_read': number;
    /**
     * 
     * @type {number}
     * @memberof WorkflowEffort
     */
    'number_of_companies_researched': number;
    /**
     * 
     * @type {number}
     * @memberof WorkflowEffort
     */
    'number_of_llm_calls': number;
}
/**
 * 
 * @export
 * @interface WorkflowFeedback
 */
export interface WorkflowFeedback {
    /**
     * 
     * @type {string}
     * @memberof WorkflowFeedback
     */
    'rating'?: WorkflowFeedbackRatingEnum;
}

export const WorkflowFeedbackRatingEnum = {
    Good: 'good',
    Bad: 'bad'
} as const;

export type WorkflowFeedbackRatingEnum = typeof WorkflowFeedbackRatingEnum[keyof typeof WorkflowFeedbackRatingEnum];

/**
 * 
 * @export
 * @interface WorkflowStub
 */
export interface WorkflowStub {
    /**
     * 
     * @type {string}
     * @memberof WorkflowStub
     */
    'workflow_id': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof WorkflowStub
     */
    'status': TaskStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkflowStub
     */
    'title': string;
    /**
     * 
     * @type {WorkflowType}
     * @memberof WorkflowStub
     */
    'workflow_type': WorkflowType;
    /**
     * 
     * @type {number}
     * @memberof WorkflowStub
     */
    'created_at'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowStub
     */
    'viewed'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WorkflowType = {
    CompFinder: 'comp_finder',
    CompLandscapeV7: 'comp_landscape_v7',
    MarketMap: 'market_map',
    MarketMapV2: 'market_map_v2',
    PublicComps: 'public_comps',
    TearSheet: 'tear_sheet',
    FindCompanies: 'find_companies',
    FindCompaniesV2: 'find_companies_v2',
    PrecedentTransactions: 'precedent_transactions',
    CustomCompanyProfiles: 'custom_company_profiles',
    GeographicCoverage: 'geographic_coverage',
    ManagementProfiles: 'management_profiles',
    PressSearch: 'press_search',
    EarningsCallPressSnippets: 'earnings_call_press_snippets',
    OfferingComparison: 'offering_comparison',
    SentimentComparison: 'sentiment_comparison',
    CaseStudies: 'case_studies',
    RegulatorySnippets: 'regulatory_snippets',
    RegulatoryEnvironments: 'regulatory_environments',
    GtmComparison: 'gtm_comparison',
    GeographicExpansion: 'geographic_expansion',
    CustomerSegmentation: 'customer_segmentation',
    MarketTrends: 'market_trends',
    BottomUpMarketSizing: 'bottom_up_market_sizing',
    TopDownMarketSizing: 'top_down_market_sizing',
    OfferingDeepdive: 'offering_deepdive',
    CompanyCustomResearch: 'company_custom_research',
    SourceCompanies: 'source_companies'
} as const;

export type WorkflowType = typeof WorkflowType[keyof typeof WorkflowType];



/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyProfilePost: async (brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_company_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetCompanyProfilePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: any): AxiosPromise<FrontendCompany> {
            return localVarFp.brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyListsApi - axios parameter creator
 * @export
 */
export const CompanyListsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest} [brainV1AddCompaniesFromMarketMapToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddCompaniesFromMarketMapToCompanyListPost: async (brainV1AddCompaniesFromMarketMapToCompanyListPostRequest?: BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/add_companies_from_market_map_to_company_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1DeleteCompanyListPostRequest} brainV1DeleteCompanyListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeleteCompanyListPost: async (brainV1DeleteCompanyListPostRequest: BrainV1DeleteCompanyListPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1DeleteCompanyListPostRequest' is not null or undefined
            assertParamExists('brainV1DeleteCompanyListPost', 'brainV1DeleteCompanyListPostRequest', brainV1DeleteCompanyListPostRequest)
            const localVarPath = `/brain/v1/delete_company_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1DeleteCompanyListPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyListsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_company_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateCompanyListPost: async (companyList: CompanyList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyList' is not null or undefined
            assertParamExists('brainV1UpdateCompanyListPost', 'companyList', companyList)
            const localVarPath = `/brain/v1/update_company_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyListsApi - functional programming interface
 * @export
 */
export const CompanyListsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyListsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest} [brainV1AddCompaniesFromMarketMapToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest?: BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1DeleteCompanyListPostRequest} brainV1DeleteCompanyListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest: BrainV1DeleteCompanyListPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetCompanyListsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetCompanyListsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UpdateCompanyListPost(companyList: CompanyList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UpdateCompanyListPost(companyList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyListsApi - factory interface
 * @export
 */
export const CompanyListsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyListsApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest} [brainV1AddCompaniesFromMarketMapToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest?: BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options?: any): AxiosPromise<CompanyList> {
            return localVarFp.brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1DeleteCompanyListPostRequest} brainV1DeleteCompanyListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest: BrainV1DeleteCompanyListPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyListsPost(options?: any): AxiosPromise<Array<CompanyList>> {
            return localVarFp.brainV1GetCompanyListsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateCompanyListPost(companyList: CompanyList, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1UpdateCompanyListPost(companyList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyListsApi - object-oriented interface
 * @export
 * @class CompanyListsApi
 * @extends {BaseAPI}
 */
export class CompanyListsApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest} [brainV1AddCompaniesFromMarketMapToCompanyListPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest?: BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1DeleteCompanyListPostRequest} brainV1DeleteCompanyListPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest: BrainV1DeleteCompanyListPostRequest, options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1GetCompanyListsPost(options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1GetCompanyListsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyList} companyList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1UpdateCompanyListPost(companyList: CompanyList, options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1UpdateCompanyListPost(companyList, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemoApi - axios parameter creator
 * @export
 */
export const DemoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1FindSimilarCompaniesLightPostRequest} [brainV1FindSimilarCompaniesLightPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FindSimilarCompaniesLightPost: async (brainV1FindSimilarCompaniesLightPostRequest?: BrainV1FindSimilarCompaniesLightPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/find_similar_companies_light`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1FindSimilarCompaniesLightPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemoApi - functional programming interface
 * @export
 */
export const DemoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1FindSimilarCompaniesLightPostRequest} [brainV1FindSimilarCompaniesLightPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest?: BrainV1FindSimilarCompaniesLightPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FrontendCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemoApi - factory interface
 * @export
 */
export const DemoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemoApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1FindSimilarCompaniesLightPostRequest} [brainV1FindSimilarCompaniesLightPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest?: BrainV1FindSimilarCompaniesLightPostRequest, options?: any): AxiosPromise<Array<FrontendCompany>> {
            return localVarFp.brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemoApi - object-oriented interface
 * @export
 * @class DemoApi
 * @extends {BaseAPI}
 */
export class DemoApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1FindSimilarCompaniesLightPostRequest} [brainV1FindSimilarCompaniesLightPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest?: BrainV1FindSimilarCompaniesLightPostRequest, options?: AxiosRequestConfig) {
        return DemoApiFp(this.configuration).brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FindCompaniesV2Api - axios parameter creator
 * @export
 */
export const FindCompaniesV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest} brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost: async (brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest: BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest' is not null or undefined
            assertParamExists('brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost', 'brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest', brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest)
            const localVarPath = `/brain/v1/add_similar_companies_to_find_companies_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindCompaniesV2InitialInputs} findCompaniesV2InitialInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FindCompaniesInitialInputsToInputsPost: async (findCompaniesV2InitialInputs: FindCompaniesV2InitialInputs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findCompaniesV2InitialInputs' is not null or undefined
            assertParamExists('brainV1FindCompaniesInitialInputsToInputsPost', 'findCompaniesV2InitialInputs', findCompaniesV2InitialInputs)
            const localVarPath = `/brain/v1/find_companies_initial_inputs_to_inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findCompaniesV2InitialInputs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FindCompaniesV2Api - functional programming interface
 * @export
 */
export const FindCompaniesV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FindCompaniesV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest} brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest: BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FindCompaniesV2InitialInputs} findCompaniesV2InitialInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs: FindCompaniesV2InitialInputs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrainV1FindCompaniesInitialInputsToInputsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FindCompaniesV2Api - factory interface
 * @export
 */
export const FindCompaniesV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FindCompaniesV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest} brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest: BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options?: any): AxiosPromise<GenericWorkflow> {
            return localVarFp.brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindCompaniesV2InitialInputs} findCompaniesV2InitialInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs: FindCompaniesV2InitialInputs, options?: any): AxiosPromise<BrainV1FindCompaniesInitialInputsToInputsPost200Response> {
            return localVarFp.brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FindCompaniesV2Api - object-oriented interface
 * @export
 * @class FindCompaniesV2Api
 * @extends {BaseAPI}
 */
export class FindCompaniesV2Api extends BaseAPI {
    /**
     * 
     * @param {BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest} brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FindCompaniesV2Api
     */
    public brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest: BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options?: AxiosRequestConfig) {
        return FindCompaniesV2ApiFp(this.configuration).brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindCompaniesV2InitialInputs} findCompaniesV2InitialInputs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FindCompaniesV2Api
     */
    public brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs: FindCompaniesV2InitialInputs, options?: AxiosRequestConfig) {
        return FindCompaniesV2ApiFp(this.configuration).brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GenericV1Api - axios parameter creator
 * @export
 */
export const GenericV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeleteGenericWorkflowPost: async (brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1FetchGenericWorkflowPostRequest' is not null or undefined
            assertParamExists('brainV1DeleteGenericWorkflowPost', 'brainV1FetchGenericWorkflowPostRequest', brainV1FetchGenericWorkflowPostRequest)
            const localVarPath = `/brain/v1/delete_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1FetchGenericWorkflowPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportWorkflowToExcelPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1ExportWorkflowToExcelPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/export_workflow_to_excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates an Powerpoint file with some sample data and returns it for download.
         * @summary Generate a powerpoint file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportWorkflowToPptPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1ExportWorkflowToPptPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/export_workflow_to_ppt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchAllGenericWorkflowsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/fetch_all_generic_workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchGenericWorkflowPost: async (brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1FetchGenericWorkflowPostRequest' is not null or undefined
            assertParamExists('brainV1FetchGenericWorkflowPost', 'brainV1FetchGenericWorkflowPostRequest', brainV1FetchGenericWorkflowPostRequest)
            const localVarPath = `/brain/v1/fetch_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1FetchGenericWorkflowPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchLibraryGenericWorkflowStubsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/fetch_library_generic_workflow_stubs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchLibraryGenericWorkflowsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/fetch_library_generic_workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchWorkflowStubsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/fetch_workflow_stubs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1GiveGenericWorkflowFeedbackPostRequest} brainV1GiveGenericWorkflowFeedbackPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GiveGenericWorkflowFeedbackPost: async (brainV1GiveGenericWorkflowFeedbackPostRequest: BrainV1GiveGenericWorkflowFeedbackPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1GiveGenericWorkflowFeedbackPostRequest' is not null or undefined
            assertParamExists('brainV1GiveGenericWorkflowFeedbackPost', 'brainV1GiveGenericWorkflowFeedbackPostRequest', brainV1GiveGenericWorkflowFeedbackPostRequest)
            const localVarPath = `/brain/v1/give_generic_workflow_feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GiveGenericWorkflowFeedbackPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1MarkWorkflowViewedPost: async (brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1FetchGenericWorkflowPostRequest' is not null or undefined
            assertParamExists('brainV1MarkWorkflowViewedPost', 'brainV1FetchGenericWorkflowPostRequest', brainV1FetchGenericWorkflowPostRequest)
            const localVarPath = `/brain/v1/mark_workflow_viewed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1FetchGenericWorkflowPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1RefineGenericWorkflowPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1RefineGenericWorkflowPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/refine_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1RunGenericWorkflowPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1RunGenericWorkflowPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/run_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateGenericWorkflowPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1UpdateGenericWorkflowPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/update_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenericV1Api - functional programming interface
 * @export
 */
export const GenericV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenericV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1ExportWorkflowToExcelPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1ExportWorkflowToExcelPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates an Powerpoint file with some sample data and returns it for download.
         * @summary Generate a powerpoint file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1ExportWorkflowToPptPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1ExportWorkflowToPptPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchAllGenericWorkflowsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericWorkflow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchAllGenericWorkflowsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchLibraryGenericWorkflowStubsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowStub>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchLibraryGenericWorkflowStubsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchLibraryGenericWorkflowsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericWorkflow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchLibraryGenericWorkflowsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchWorkflowStubsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowStub>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchWorkflowStubsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1GiveGenericWorkflowFeedbackPostRequest} brainV1GiveGenericWorkflowFeedbackPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest: BrainV1GiveGenericWorkflowFeedbackPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1RefineGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1RefineGenericWorkflowPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1RunGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1RunGenericWorkflowPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UpdateGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UpdateGenericWorkflowPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GenericV1Api - factory interface
 * @export
 */
export const GenericV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenericV1ApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportWorkflowToExcelPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<File> {
            return localVarFp.brainV1ExportWorkflowToExcelPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates an Powerpoint file with some sample data and returns it for download.
         * @summary Generate a powerpoint file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportWorkflowToPptPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<File> {
            return localVarFp.brainV1ExportWorkflowToPptPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchAllGenericWorkflowsPost(options?: any): AxiosPromise<Array<GenericWorkflow>> {
            return localVarFp.brainV1FetchAllGenericWorkflowsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: any): AxiosPromise<GenericWorkflow> {
            return localVarFp.brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchLibraryGenericWorkflowStubsPost(options?: any): AxiosPromise<Array<WorkflowStub>> {
            return localVarFp.brainV1FetchLibraryGenericWorkflowStubsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchLibraryGenericWorkflowsPost(options?: any): AxiosPromise<Array<GenericWorkflow>> {
            return localVarFp.brainV1FetchLibraryGenericWorkflowsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchWorkflowStubsPost(options?: any): AxiosPromise<Array<WorkflowStub>> {
            return localVarFp.brainV1FetchWorkflowStubsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1GiveGenericWorkflowFeedbackPostRequest} brainV1GiveGenericWorkflowFeedbackPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest: BrainV1GiveGenericWorkflowFeedbackPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1RefineGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<GenericWorkflow> {
            return localVarFp.brainV1RefineGenericWorkflowPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1RunGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<GenericWorkflow> {
            return localVarFp.brainV1RunGenericWorkflowPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1UpdateGenericWorkflowPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenericV1Api - object-oriented interface
 * @export
 * @class GenericV1Api
 * @extends {BaseAPI}
 */
export class GenericV1Api extends BaseAPI {
    /**
     * 
     * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates an Excel file with some sample data and returns it for download.
     * @summary Generate an Excel file
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1ExportWorkflowToExcelPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1ExportWorkflowToExcelPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates an Powerpoint file with some sample data and returns it for download.
     * @summary Generate a powerpoint file
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1ExportWorkflowToPptPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1ExportWorkflowToPptPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchAllGenericWorkflowsPost(options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchAllGenericWorkflowsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchLibraryGenericWorkflowStubsPost(options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchLibraryGenericWorkflowStubsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchLibraryGenericWorkflowsPost(options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchLibraryGenericWorkflowsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchWorkflowStubsPost(options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchWorkflowStubsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1GiveGenericWorkflowFeedbackPostRequest} brainV1GiveGenericWorkflowFeedbackPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest: BrainV1GiveGenericWorkflowFeedbackPostRequest, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1RefineGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1RefineGenericWorkflowPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1RunGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1RunGenericWorkflowPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1UpdateGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1UpdateGenericWorkflowPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SourceCompaniesApi - axios parameter creator
 * @export
 */
export const SourceCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SourceCompaniesInputs} sourceCompaniesInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SourceCompaniesInitialInputsToInputsPost: async (sourceCompaniesInputs: SourceCompaniesInputs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceCompaniesInputs' is not null or undefined
            assertParamExists('brainV1SourceCompaniesInitialInputsToInputsPost', 'sourceCompaniesInputs', sourceCompaniesInputs)
            const localVarPath = `/brain/v1/source_companies_initial_inputs_to_inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sourceCompaniesInputs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SourceCompaniesApi - functional programming interface
 * @export
 */
export const SourceCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SourceCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SourceCompaniesInputs} sourceCompaniesInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs: SourceCompaniesInputs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrainV1SourceCompaniesInitialInputsToInputsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SourceCompaniesApi - factory interface
 * @export
 */
export const SourceCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SourceCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {SourceCompaniesInputs} sourceCompaniesInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs: SourceCompaniesInputs, options?: any): AxiosPromise<BrainV1SourceCompaniesInitialInputsToInputsPost200Response> {
            return localVarFp.brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SourceCompaniesApi - object-oriented interface
 * @export
 * @class SourceCompaniesApi
 * @extends {BaseAPI}
 */
export class SourceCompaniesApi extends BaseAPI {
    /**
     * 
     * @param {SourceCompaniesInputs} sourceCompaniesInputs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourceCompaniesApi
     */
    public brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs: SourceCompaniesInputs, options?: AxiosRequestConfig) {
        return SourceCompaniesApiFp(this.configuration).brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetUserDefaultScopePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_user_default_scope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1SetUserDefaultScopePostRequest} brainV1SetUserDefaultScopePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SetUserDefaultScopePost: async (brainV1SetUserDefaultScopePostRequest: BrainV1SetUserDefaultScopePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1SetUserDefaultScopePostRequest' is not null or undefined
            assertParamExists('brainV1SetUserDefaultScopePost', 'brainV1SetUserDefaultScopePostRequest', brainV1SetUserDefaultScopePostRequest)
            const localVarPath = `/brain/v1/set_user_default_scope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1SetUserDefaultScopePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetUserDefaultScopePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportableSearchScope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetUserDefaultScopePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1SetUserDefaultScopePostRequest} brainV1SetUserDefaultScopePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest: BrainV1SetUserDefaultScopePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetUserDefaultScopePost(options?: any): AxiosPromise<ImportableSearchScope> {
            return localVarFp.brainV1GetUserDefaultScopePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1SetUserDefaultScopePostRequest} brainV1SetUserDefaultScopePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest: BrainV1SetUserDefaultScopePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public brainV1GetUserDefaultScopePost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).brainV1GetUserDefaultScopePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1SetUserDefaultScopePostRequest} brainV1SetUserDefaultScopePostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest: BrainV1SetUserDefaultScopePostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


