import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { CompanyDomainAutocompleteForLeftbar } from "@/components/workflow-inputs/companyWebsiteAutocomplete";
import { isValidUrl } from "@/lib/utils";
import axios from "axios";
import posthog from "posthog-js";
import { useState } from "react";
import { Toaster, toast } from "sonner";

export function LandingPage() {
  const lambdaBaseUrl = "https://cc23vqfjhgkrpnk2fvx3pxxtkq0grlhf.lambda-url.us-east-1.on.aws/";
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [company, setCompany] = useState("");

  return (
    <>
      <div className="flex flex-col h-screen dark relative">
        <div className="w-screen h-15 bg-[#111013] p-5 flex flex-row items-center">
          <img src="/MeticulateIconSquare.svg" className="h-10" />
        </div>

        <div
          className="p-4 flex flex-col h-full w-screen items-center md:justify-center"
          style={{ background: "radial-gradient(94% 58.3% at 50.3% 82.6%, #2a2541 0%, rgb(17, 16, 19) 100%)" }}>
          <div className="flex-grow"></div>
          {/* <div className="md:text-8xl flex font-semibold mb-3 text-center text-6xl text-white">Effortless</div> */}
          <div className="md:text-8xl text-5xl flex text-[#7F5EFF] font-semibold text-center md:mt-0 mt-5">Meticulate</div>
          <div className="md:text-3xl text-2xl flex mt-5 mb-10 font-landing font-light text-[#e8e3fd] text-center opacity-80">
            Effortless company intelligence
          </div>
          <Button
            className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white mb-4 shadow-[0_0_10px_rgba(255,255,255,0.5)] hover:shadow-[0_0_10px_rgba(255,255,255,0.6)]"
            onClick={() => {
              window.location.href = "https://app.meticulate.ai";
            }}>
            Try the research engine
          </Button>

          <div className="flex flex-col flex-wrap items-center justify-center w-full gap-4">
            <div className="text-[#e8e3fd]">or</div>
            <div className="flex flex-col items-center gap-2">
              <div className="text-[#e8e3fd]">Try the best similar companies search in the world</div>
              <div
                className="flex flex-row items-center gap-4 bg-white text-white rounded-lg p-2 mb-5 relative z-50"
                style={{ transformStyle: "preserve-3d" }}>
                <CompanyDomainAutocompleteForLeftbar
                  placeholder="Company domain"
                  onChange={(e) => setCompany(e)}
                  onSubmit={({ value }) => {
                    setCompany(value);
                  }}
                />
                <Button
                  type="submit"
                  onClick={() => {
                    if (company && isValidUrl(company)) {
                      window.location.href = `/find-similar?company_domain=${encodeURIComponent(company)}`;
                    } else {
                      toast.error("Please enter a valid company domain");
                    }
                  }}
                  className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white">
                  Search
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="text-[#e8e3fd]">Try the best similar companies search in the world</div> */}
          <div className="text-[#e8e3fd] mb-2">Private Beta</div>
          <div className="flex flex-row gap-2 flex-wrap items-center justify-center">
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  className="w-30 h-8 text-xs"
                  onClick={() => {
                    // toast.info("Not available for public access yet");
                    posthog.capture("user_clicked_on_company_intelligence_platform");
                  }}>
                  Company intelligence platform
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Still in beta - Try the research engine instead</DialogTitle>
                  <DialogDescription>
                    The company intelligence platform is not publicly available yet. You can request access here and try the research engine
                    in the meantime.
                  </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <label htmlFor="email" className="text-right">
                      Email
                    </label>
                    <Input
                      type="email"
                      id="email"
                      placeholder="youremail@gmail.com"
                      className="col-span-3"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <label htmlFor="note" className="text-right">
                      Note
                    </label>
                    <AutosizedTextarea
                      id="note"
                      placeholder="Any thing you want us to know"
                      className="col-span-3"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </div>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button
                      type="submit"
                      onClick={() => {
                        posthog.capture("user_requested_access_to_company_intelligence_platform");
                        axios.get(lambdaBaseUrl + "?email=" + encodeURIComponent(email) + "&note=" + encodeURIComponent(note));
                        toast.info("Thanks for requesting access! We'll be in touch!");
                        setEmail("");
                        setNote("");
                      }}>
                      Request access
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  className="text-xs w-30 h-8"
                  onClick={() => {
                    // toast.info("Not available for public access yet");
                    posthog.capture("user_clicked_on_company_intelligence_platform");
                  }}>
                  API access
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Request API access</DialogTitle>
                  <DialogDescription>
                    The API is currently in private beta. You can request access here and try the research engine in the meantime.
                  </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <label htmlFor="email" className="text-right">
                      Email
                    </label>
                    <Input
                      type="email"
                      id="email"
                      placeholder="youremail@gmail.com"
                      className="col-span-3"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <label htmlFor="note" className="text-right">
                      Note
                    </label>
                    <AutosizedTextarea
                      id="note"
                      placeholder="Any thing you want us to know"
                      className="col-span-3"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </div>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button
                      type="submit"
                      onClick={() => {
                        posthog.capture("user_requested_access_to_api");
                        axios.get(
                          lambdaBaseUrl +
                            "?email=" +
                            encodeURIComponent(email) +
                            "&note=" +
                            encodeURIComponent("API ACCESS REQUEST: " + note)
                        );
                        toast.info("Thanks for requesting access! We'll be in touch!");
                        setEmail("");
                        setNote("");
                      }}>
                      Request access
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex-grow"></div>
          <div className="flex flex-col items-center mt-auto">
            <div className="text-xl flex mb-5 font-normal text-[#e8e3fd] text-center opacity-50 tracking-[.13em]">BACKED BY</div>
            <div className="flex flex-row gap-x-10 gap-y-2 flex-wrap items-center content-center justify-center">
              <a href="https://www.ycombinator.com/launches/KUx-meticulate-effortless-business-research" target="_blank">
                <img
                  className="md:h-10 h-6 opacity-80"
                  alt="Launch YC: Meticulate – Effortless business research"
                  src="https://www.ycombinator.com/launches/KUx-meticulate-effortless-business-research/upvote_embed.svg"
                />
              </a>
              <img src="/leonis_capital.webp" className="md:h-10 h-6 rounded opacity-70" />
              <img src="/Cervin_logo.svg" className="md:h-20 h-12 rounded opacity-70 p-1" />
              <img src="/link_logo.png" className="md:h-8 h-5 rounded opacity-70" />
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" richColors />
    </>
  );
}
